import { Link, useSearchParams } from "@remix-run/react";
import { Callout } from "~/components/Callout";
import { AUTH_ERROR } from "~/utils/auth";
import { TextLink } from "~/components/TextLink";

const AUTH_ERROR_MAP: Record<AUTH_ERROR, string> = {
  [AUTH_ERROR.missing_ods_code]:
    "GP Connect can only be accessed by providers with an authorised ODS code. Please contact Support to verify your ODS code.",
  [AUTH_ERROR.something_went_wrong]: "Something went wrong",
  [AUTH_ERROR.user_session_not_found]:
    "Your current GP Connect session was either not found or has expired.",
};

export default function Unauthorised() {
  const showDevlink = process.env.NODE_ENV === "development";
  const [searchParams] = useSearchParams();

  const error = searchParams.get("error") as AUTH_ERROR | null;
  const errorMessage = error ? AUTH_ERROR_MAP[error] : undefined;
  return (
    <main className={"container"}>
      <section className="container__body styled">
        <header>
          <h1>Unauthorised</h1>
        </header>
        <p>
          You have been logged out. Please return to the Agency Hub application
          to re-open the GP Connect Record Viewer.
        </p>
        {showDevlink && (
          <TextLink>
            <Link to="/login?code=1">Go back to login (dev only)</Link>
          </TextLink>
        )}
        {errorMessage && (
          <Callout border={true}>
            <Callout.Icon variant="warning" />
            <Callout.Text>{errorMessage}</Callout.Text>
          </Callout>
        )}
      </section>
    </main>
  );
}
